/* eslint quote-props: ['off']  */
import { format, parseISO } from 'date-fns';
import {
  ar, enGB, enUS, enIN, es, fr, bn, gu, hi, ru, ta,
} from 'date-fns/locale';
import $ from 'jquery';

const updateHtml = formatPattern => (_, el) => {
  const localeMap = {
    'ar': ar,
    'en': enUS,
    'en-in': enIN,
    'es': es,
    'fr': fr,
    'bn': bn,
    'gu': gu,
    // date-fns does not support Marshallese, so fallback to US English
    // (according to Wikipedia, Palau uses the mm/dd/yyyy date format)
    'mh': enUS,
    // date-fns does not support Nepali, so fallback to English - but India
    // English will probably have more relevant defaults than enUS
    'ne': enIN,
    'hi': hi,
    // date-fns does not support Punjabi, so fallback to English - but India
    // English will probably have more relevant defaults than enUS
    'pa': enIN,
    // date-fns does not support Palauan, so fallback to English - but British
    // English will probably have more relevant defaults than enUS (according to
    // Wikipedia, Palau uses the dd-mm-yyyy date format)
    'pau': enGB,
    'ru': ru,
    'ta': ta,
  };

  const datetimeValue = $(el).attr('datetime');
  const locale = localeMap[$(el).attr('data-locale')] || enUS;

  if (!datetimeValue) {
    return;
  }

  const time = parseISO(datetimeValue);
  $(el).html(format(time, formatPattern, { locale }));
};

$('.convertible-time').each(updateHtml('pp P'));

$('.convertible-time__long').each(updateHtml('PPPPpp'));

$('.convertible-time__long-date').each(updateHtml('MMMM dd, yyyy'));

$('.convertible-time__short').each(updateHtml('P'));
